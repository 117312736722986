import { RouteBuilder } from '@sender-portal-fe/util-shared/src/routing/RouteBuilder';

export type SenderAnalyticsRoute =
  | {
      id: 'root';
    }
  | {
      id: 'senderAnalyticsContent';
      organizationKey: string;
      senderKey: string;
    }
  | {
      id: 'organizationRootOther';
      organizationKey: string;
    }
  | {
      id: 'senderAnalyticsOther';
      organizationKey: string;
      senderKey: string;
    }
  | {
      id: 'organizationRootContent';
      organizationKey: string;
    };

export type RouteParams<Id extends SenderAnalyticsRoute['id']> = Omit<
  Extract<SenderAnalyticsRoute, { id: Id }>,
  'id'
>;

export type SenderAnalyticsExternalRoutes = Extract<
  SenderAnalyticsRoute['id'],
  'organizationRootContent' | 'organizationRootOther'
>;

export const externalRoutes: SenderAnalyticsExternalRoutes[] = [
  'organizationRootContent',
  'organizationRootOther',
];

export const {
  getBasePath,
  setBasePath,
  getPath,
  getRoute,
  getRoutes,
  createGetPath,
} = new RouteBuilder<SenderAnalyticsRoute>({
  root: '/',
  organizationRootContent: '/organization/:organizationKey/content',
  organizationRootOther: '/organization/:organizationKey/other',
  senderAnalyticsContent:
    '/organization/:organizationKey/content/sender/:senderKey',
  senderAnalyticsOther:
    '/organization/:organizationKey/other/sender/:senderKey',
});
