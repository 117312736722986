import { history } from '../routing/history';
import { createHeimdallAuthentication } from '../sdk/authentication/heimdall';
import type { HeimdallQueryParameters } from '../sdk/authentication/types/heimdall';
import { setTokenToSession } from './setToken';

type MinimumConfig = Record<
  | 'sender_api_origin'
  | 'sender_portal_heimdall_client_id'
  | 'sender_portal_origin',
  string
>;

type LoginOptions<Config> = {
  config: Config;
  heimdallQueryParameters: HeimdallQueryParameters;
  onLoginRoute?: string;
};

// TODO: Use this login function for all apps
export async function login<Config extends MinimumConfig>({
  config,
  heimdallQueryParameters,
  onLoginRoute = '/',
}: LoginOptions<Config>): Promise<void> {
  const {
    sender_portal_heimdall_client_id,
    sender_api_origin,
    sender_portal_origin,
  } = config;
  const { authorizationCode, redirectState, error } = heimdallQueryParameters;
  const heimdallAuthentication = createHeimdallAuthentication<{
    route: string;
  }>({
    clientId: sender_portal_heimdall_client_id,
    heimdallUrl: sender_api_origin,
    redirectUrl: sender_portal_origin,
  });
  if (authorizationCode && redirectState) {
    const { token, applicationState, error } =
      await heimdallAuthentication.getToken(authorizationCode, redirectState);
    if (error || !applicationState) {
      throw new Error('Could not login error');
    } else {
      setTokenToSession(token);
      history.push(applicationState.route);
    }
  } else if (error) {
    throw new Error('Could not login error');
  } else {
    await heimdallAuthentication.initiateLogin({
      route: onLoginRoute,
    });
  }
}
