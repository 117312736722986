import { RouteBuilder } from '@sender-portal-fe/util-shared/src/routing/RouteBuilder';

export type SenderInfoRoute =
  | { id: 'root' }
  | {
      id: 'senderInfo';
      organizationKey: string;
      senderKey: string;
    }
  | {
      id: 'organizationRoot';
      organizationKey: string;
    };

export type RouteParams<Id extends SenderInfoRoute['id']> = Omit<
  Extract<SenderInfoRoute, { id: Id }>,
  'id'
>;

export type SenderInfoExternalRoutes = Extract<
  SenderInfoRoute['id'],
  'organizationRoot'
>;

export const externalRoutes: SenderInfoExternalRoutes[] = ['organizationRoot'];

export const {
  getBasePath,
  setBasePath,
  getPath,
  getRoute,
  getRoutes,
  createGetPath,
} = new RouteBuilder<SenderInfoRoute>({
  root: '/',
  organizationRoot: '/organization/:organizationKey',
  senderInfo: '/organization/:organizationKey/sender/:senderKey',
});
