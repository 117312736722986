import { styled } from '@kivra/react-components';

export const BaseLayout = styled('div')({
  position: 'relative',
  minHeight: 'calc(100vh - 100px)',
  maxWidth: '1280px', // theme.breakpoints.values.lg,
  padding: ['$spacing-12', '$spacing-24', '$spacing-48', '$spacing-24'],
  margin: 'auto',
  $medium: {
    padding: ['$spacing-48', '$spacing-16', 0],
  },
  $small: {
    padding: ['$spacing-24', '$spacing-16'],
  },
});
