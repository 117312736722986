import { RouteBuilder } from '@sender-portal-fe/util-shared/src/routing/RouteBuilder';

export type BackofficeRoute =
  | {
      id: 'organization';
      organizationKey: string;
    }
  | {
      id: 'root';
    };

export const {
  getBasePath,
  setBasePath,
  getPath,
  getRoute,
  getRoutes,
  createGetPath,
} = new RouteBuilder<BackofficeRoute>({
  organization: '/organization/:organizationKey',
  root: '/',
});
