import { userSession } from '@kivra/sdk/identity';
import React from 'react';
import { revokeToken } from '../sdk/authentication/revokeToken';
import { decodeIdToken } from '../sdk/authentication/heimdall';
import type { PersonaIdToken } from '../sdk/authentication/types/heimdall';
import { LoggedInUserContextProvider } from './loggedInUserContext';

interface Props {
  adminOrganizationId: string;
  AccessDeniedComponent: () => React.JSX.Element;
  children: React.ReactNode;
}

export const LoggedInUserProvider = ({
  adminOrganizationId,
  AccessDeniedComponent,
  children,
}: Props): React.JSX.Element => {
  let loggedInUser = null;
  const idToken = userSession.getSession()?.userId;

  if (!idToken) {
    throw new Error('no user id');
  }

  const decodedToken = decodeIdToken<PersonaIdToken>(idToken);
  loggedInUser = {
    email: decodedToken.email,
    isAdmin: decodedToken.organizations?.includes(adminOrganizationId) || false,
    organizationIds:
      decodedToken.organizations?.filter(key => key !== adminOrganizationId) ||
      [],
  };

  if (loggedInUser.organizationIds.length === 0) {
    void revokeToken().finally(() => <AccessDeniedComponent />);
  }

  return (
    <LoggedInUserContextProvider value={{ loggedInUser }}>
      {children}
    </LoggedInUserContextProvider>
  );
};
